@import "./common-variables.scss";

html,
body,
app-dashboard {
  height: 100%;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hidden {
  display: none;
}

.hidden-by-size {
  width: 0;
  height: 0;
  overflow: hidden;
}

.score-view-wrapper {
  //min-height: $score-component-height;
  padding-right: calc($score-component-width + 10px);
  position: relative;
  //margin-bottom: 20px;
}

.mat-success-text {
  color: $success-color;
}


.mdc-snackbar {
  .mat-mdc-snack-bar-label {
    display: flex;
    justify-content: center;
  }

  &.success {
    --mdc-snackbar-container-color: var(--success-900);
  }

  &.error {
    --mdc-snackbar-container-color: var(--warn-color, #e22525);
  }
}

.has-sticky-footer mat-card {
  padding-bottom: 0;
}

app-footer {
  position: sticky;
  bottom: 0;
  z-index: 21;
  background: #fff;
  display: block;
  margin-top: auto;
  height: $footer-height;
  line-height: $footer-height;
}

app-data-maintenance-footer-button {
  display: block;
  background-color: #fff;
  padding: 0.5rem 0;
  position: sticky;
  bottom: $footer-height;
  z-index: 10;
}

//pdf viewer overwrites
pdf-viewer .ng2-pdf-viewer-container {
  position: static;
}

.success {
  color: darken($success-color, 10);
}

.external {
  color: rgb(207, 149, 23);
}

.info {
  color: var(--primary-color, #011389);
}

.warning {
  color: var(--warn-color, #e22525);
}

.mat-mdc-tooltip {
  white-space: pre-line;
  text-align: left;
}

.mat-mdc-snack-bar-container {
  &.info {
    --mdc-snackbar-container-color: var(--primary-color, #011389);
    --mat-mdc-snack-bar-button-color: var(--light-text, #fafafa);
    ;
    --mdc-snackbar-supporting-text-color: var(--light-text, #fafafa);
  }
}

table {
  /* row spacing / margin */
  border-spacing: 0;
}

mat-card-content {
  //overflow: hidden;
}

.engagement-table-wrapper {
  overflow: auto;
  max-height: 155px;
  min-height: 60px;
}

.engagement-table {
  width: 100%;

  thead {
    background-color: #FFF;
    z-index: 1;
  }

  tr {
    td {
      $base-item-padding: 0.5rem;

      textarea,
      input {
        min-height: 30px;
        width: 100%;
      }

      input,
      .description {
        padding: 0 $base-item-padding;
      }

      // textarea {
      //   display: block;
      //   padding: 6px $base-item-padding 0 $base-item-padding;
      // }

      &:nth-child(1) {
        width: 55px;

        .description {
          max-width: 55px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &:nth-child(2) {
        width: 105px;
      }

      &:nth-child(3) {
        width: calc(80% - 105px - 55px);
      }

      &:nth-child(4) {
        width: 20%;
      }
    }
  }
}

.settings-modal-content {
  max-height: 600px;
  min-width: 750px;
  position: relative;
  padding: 48px;

  &>h2 {
    width: 50%;
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 1;
    padding: 1rem 0;
  }
}

.user {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(.user--simple) {
    width: 300px;
    height: 90px;
    padding: 0.25rem 0 0.25rem 0.5rem;
    border: solid 1px var(--accent-400);
    box-shadow: 0px 10px 15px -12px var(--accent-400);
  }

  &--simple {
    .user__avatar {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      line-height: 40px;
      margin-right: 0;
      font-size: .9rem;
    }
  }

  &:not(.user--simple) .__avatar {
    min-height: 50px;
    min-width: 50px;
  }

  &__avatar {
    height: 50px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: var(--accent-400);
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 1rem;
    margin-right: 1rem;
  }

  &_info {
    width: 180px;
  }
}

.batch-update {

  .mat-mdc-text-field-wrapper.mdc-text-field {
    max-height: 50px;
  }
}
